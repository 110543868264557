import {
  WorkspaceOverview,
  WorkspaceSectionLayout,
} from "@dewo/app/graphql/types";
import { Col, List, Row, Typography } from "antd";
import React, { FC, ReactNode, useMemo } from "react";
import { WorkspaceGridItem } from "./WorkspaceGridItem";
import { WorkspaceListItem } from "./WorkspaceListItem";

interface Props {
  title: string;
  workspaces: WorkspaceOverview[];
  layout: WorkspaceSectionLayout;
  extra?: ReactNode[];
}

export const OverviewWorkspaceSection: FC<Props> = ({
  title,
  workspaces,
  extra,
  layout,
}) => {
  const content = useMemo(() => {
    if (!workspaces.length) {
      return (
        <Typography.Text type="secondary">
          This section is empty
        </Typography.Text>
      );
    }

    if (layout === WorkspaceSectionLayout.GRID) {
      return (
        <Row gutter={[8, 8]}>
          {workspaces.map((workspace) => (
            <Col key={workspace.id} xs={24} sm={12} xl={8} xxl={8}>
              <WorkspaceGridItem workspace={workspace} />
            </Col>
          ))}
        </Row>
      );
    }

    if (layout === WorkspaceSectionLayout.LIST) {
      return (
        <List
          dataSource={workspaces}
          renderItem={(workspace) => (
            <WorkspaceListItem workspace={workspace} />
          )}
        />
      );
    }

    return null;
  }, [workspaces, layout]);

  return (
    <section>
      <Row align="middle" style={{ marginBottom: 8, columnGap: 8 }}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
        {extra}
      </Row>
      {content}
    </section>
  );
};
