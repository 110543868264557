import { MoreOutlined } from "@ant-design/icons";
import { useUpdateWorkspace } from "@dewo/app/containers/workspace/hooks";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { Workspace } from "@dewo/app/graphql/types";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import { eatClick } from "@dewo/app/util/eatClick";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, Dropdown, Menu } from "antd";
import React, { FC, useCallback } from "react";
import { useOrganizationDetails } from "../../hooks";
import { DuplicateWorkspace } from "./DuplicateWorkspace";

interface Props {
  workspace: Workspace;
}

export function useShowChangeSection(workspace: Workspace): boolean {
  const canChangeSection = usePermission("update", workspace, "sectionId");
  const { organization } = useOrganizationDetails(workspace.organizationId);
  return !!canChangeSection && !!organization?.workspaceSections.length;
}

export const WorkspaceOptionsButton: FC<Props> = ({ workspace }) => {
  const { logEvent } = useAmplitude();
  const { organization } = useOrganizationDetails(workspace.organizationId);

  const updateWorkspace = useUpdateWorkspace();
  const handleMoveSection = useCallback(
    async (sectionId: string | null) => {
      await updateWorkspace({
        id: workspace.id,
        sectionId,
        sortKey: Date.now().toString(),
      });
    },
    [workspace.id, updateWorkspace]
  );

  const showDuplicateModal = useToggle();

  const shouldShow = usePermission("update", workspace);
  const shouldShowSection = useShowChangeSection(workspace);
  if (!shouldShow) return null;
  return (
    <>
      <Dropdown
        placement="bottomRight"
        trigger={["click"]}
        overlay={
          <Menu>
            {!!shouldShowSection && (
              <Menu.SubMenu title="Change Section">
                {!!workspace.sectionId && (
                  <Menu.Item
                    onClick={(e) => {
                      eatClick(e.domEvent);
                      handleMoveSection(null);
                    }}
                  >
                    Spaces
                  </Menu.Item>
                )}
                {organization?.workspaceSections
                  .filter((section) => section.id !== workspace.sectionId)
                  .map((section) => (
                    <Menu.Item
                      key={section.id}
                      onClick={(e) => {
                        eatClick(e.domEvent);
                        handleMoveSection(section.id);
                      }}
                    >
                      {section.name}
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            )}
            <Menu.Item
              onClick={(e) => {
                eatClick(e.domEvent);
                logEvent("Show duplicate workspace", {
                  workspaceId: workspace.id,
                });
                showDuplicateModal.toggleOn();
              }}
            >
              Duplicate
            </Menu.Item>
          </Menu>
        }
      >
        <Button
          type="text"
          size="small"
          style={{ position: "absolute", top: 4, right: 4 }}
          icon={<MoreOutlined />}
          className="dewo-task-options-button"
        />
      </Dropdown>
      <DuplicateWorkspace
        visible={showDuplicateModal.isOn}
        workspace={workspace}
        organization={organization}
        onClose={showDuplicateModal.toggleOff}
      />
    </>
  );
};
