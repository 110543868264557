import {
  AppstoreOutlined,
  CheckCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { SkillTag } from "@dewo/app/components/tags/SkillTag";
import { WorkspaceName } from "@dewo/app/containers/workspace/WorkspaceName";
import { WorkspaceOverview, WorkspaceType } from "@dewo/app/graphql/types";
import { Card, Col, Progress, Row, Typography } from "antd";
import Link from "next/link";
import React, { FC } from "react";
import {
  useShowChangeSection,
  WorkspaceOptionsButton,
} from "./WorkspaceOptionsButton";

interface Props {
  workspace: WorkspaceOverview;
}

export const WorkspaceGridItem: FC<Props> = ({ workspace }) => {
  const showOptionsButton = useShowChangeSection(workspace);
  const numChildProjects = workspace.node.workspaceChildren.filter(
    (edge) => edge.node.workspace?.type === WorkspaceType.PROJECT
  ).length;
  return (
    <Link href={workspace.permalink}>
      <a>
        <Card
          size="small"
          bodyStyle={{
            padding: "0px 12px",
            height: "100%",
            minHeight: 100,
            display: "flex",
            flexDirection: "column",
            rowGap: 8,
          }}
          className="bg-body-secondary hover:component-highlight cursor-pointer h-full"
        >
          <Row
            style={
              showOptionsButton
                ? { paddingRight: 16 /* WorkspaceOptionsButton */ }
                : undefined
            }
          >
            <Typography.Paragraph
              style={{ margin: 0, flex: 1 }}
              className="font-semibold"
            >
              <WorkspaceName workspace={workspace} />
            </Typography.Paragraph>
            {workspace.type === WorkspaceType.PROJECT &&
              !!workspace.totalTaskCount && (
                <Typography.Text
                  type="secondary"
                  className="ant-typography-caption"
                >
                  {Math.round(
                    (workspace.doneTaskCount / workspace.totalTaskCount) * 100
                  )}
                  %
                  <Progress
                    type="circle"
                    strokeWidth={12}
                    percent={
                      (workspace.doneTaskCount / workspace.totalTaskCount) * 100
                    }
                    showInfo={false}
                    width={14}
                    style={{ marginTop: -2, marginLeft: 4 }}
                  />
                </Typography.Text>
              )}
          </Row>

          <Row style={{ rowGap: 4, flex: 1, alignItems: "flex-start" }}>
            {workspace.skills.slice(0, 3).map((s) => (
              <SkillTag key={s.skill.id} skill={s.skill} mode="emoji" />
            ))}
          </Row>
          <Row gutter={24}>
            {!!numChildProjects && (
              <Col className="text-secondary ant-typography-caption">
                <AppstoreOutlined style={{ marginRight: 4 }} />
                {numChildProjects === 1
                  ? "1 project"
                  : `${numChildProjects} projects`}
              </Col>
            )}
            <Col className="text-secondary ant-typography-caption">
              <CheckCircleOutlined style={{ marginRight: 4 }} />
              {workspace.openTaskCount} open tasks
            </Col>
            <Col className="text-secondary ant-typography-caption">
              <UsergroupAddOutlined style={{ marginRight: 4 }} />
              {workspace.contributors?.length === 1
                ? "1 contributor"
                : `${workspace.contributors?.length} contributors`}
            </Col>
          </Row>

          <WorkspaceOptionsButton workspace={workspace} />
        </Card>
      </a>
    </Link>
  );
};
