import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { WorkspaceName } from "@dewo/app/containers/workspace/WorkspaceName";
import { WorkspaceOverview, WorkspaceType } from "@dewo/app/graphql/types";
import { Avatar, Divider, List, Progress } from "antd";
import Link from "next/link";
import React, { FC } from "react";
import styles from "./WorkspaceListItem.module.less";

interface Props {
  workspace: WorkspaceOverview;
}

export const WorkspaceListItem: FC<Props> = ({ workspace }) => {
  return (
    <Link href={workspace.permalink}>
      <a>
        <Divider style={{ margin: 0 }} />
        <List.Item className={styles.listItem}>
          <WorkspaceName workspace={workspace} />
          {workspace.type === WorkspaceType.PROJECT && (
            <Progress
              percent={Math.round(
                (workspace.doneTaskCount / (workspace.totalTaskCount || 1)) *
                  100
              )}
              status="normal"
            />
          )}
          <Avatar.Group
            maxCount={3}
            size="small"
            style={{ width: 72 }}
            className="hide-sm"
          >
            {workspace.contributors.map((user) => (
              <UserAvatar key={user.id} user={user} linkToProfile />
            ))}
          </Avatar.Group>
        </List.Item>
      </a>
    </Link>
  );
};
