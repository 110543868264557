import {
  SearchTasksInput,
  TaskStatus,
  TaskViewField,
  TaskViewSortByDirection,
  TaskViewSortByField,
} from "@dewo/app/graphql/types";
import {
  Button,
  Col,
  Divider,
  Empty,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { FC, useMemo, useState } from "react";
import { TaskTable } from "@dewo/app/containers/task/list/TaskTable";
import { useTaskViewLayoutData } from "@dewo/app/containers/task/views/hooks";
import { useLocalState, useMounted } from "@dewo/app/util/hooks";
import { useSkills } from "@dewo/app/containers/skills/hooks";
import { DropdownSelect } from "@dewo/app/components/form/DropdownSelect";
import { TaskViewFieldsProvider } from "@dewo/app/containers/task/views/TaskViewFieldsContext";
import { SkillAvatar } from "../avatars/SkillAvatar";
import { DownOutlined } from "@ant-design/icons";

interface Props {
  organizationId: string | undefined;
  workspaceId?: string;
  roleId?: string;
  title?: string;
}

enum SortBy {
  recent = "recent",
  high = "high",
  recentBounties = "recentBounties",
}

const fields: TaskViewField[] = [
  TaskViewField.gating,
  TaskViewField.name,
  TaskViewField.skills,
  TaskViewField.reward,
  TaskViewField.createdAt,
];

const SortNameMapping: Partial<Record<SortBy, string>> = {
  [SortBy.high]: "Highest bounties",
  [SortBy.recentBounties]: "Recent bounties",
};

export const TaskDiscoveryList: FC<Props> = ({
  organizationId,
  workspaceId,
  roleId,
  title = "Open Tasks",
}) => {
  const [skillIds, setSkillIds] = useState<string[]>([]);
  const skills = useSkills();

  const [sortBy, setSortBy] = useLocalState<SortBy>(
    `Organization.${organizationId}.sortBy`,
    SortBy.high
  );

  const [data] = useTaskViewLayoutData(
    useMemo(
      (): SearchTasksInput[] =>
        !!organizationId
          ? [
              {
                workspaceIds: workspaceId ? [workspaceId] : undefined,
                organizationIds: workspaceId ? undefined : [organizationId],
                roleIds: roleId ? [roleId] : undefined,
                statuses: [TaskStatus.TODO],
                sortBy: {
                  field:
                    sortBy === SortBy.high
                      ? TaskViewSortByField.reward
                      : TaskViewSortByField.createdAt,
                  direction: TaskViewSortByDirection.DESC,
                },
                reward: {
                  exists: sortBy === SortBy.recentBounties ? true : undefined,
                },
                assigneeIds: [null],
                parentTaskId: null,
                skillIds: !!skillIds.length ? skillIds : undefined,
              },
            ]
          : [],
      [organizationId, roleId, skillIds, sortBy, workspaceId]
    )
  );

  const mounted = useMounted();
  if (!organizationId) return null;
  return (
    <div>
      <Row wrap={false}>
        <Col flex={1}>
          <Typography.Title ellipsis level={5}>
            {title}
          </Typography.Title>
        </Col>
        <Col>
          <Space>
            <DropdownSelect<string, string[]>
              mode="multiple"
              options={skills?.map((s) => ({
                value: s.id,
                label: (
                  <Row align="middle">
                    <SkillAvatar
                      size={16}
                      style={{ marginRight: 8 }}
                      skill={s}
                    />
                    {s.name}
                  </Row>
                ),
              }))}
              value={skillIds}
              onChange={setSkillIds}
            >
              <Button size="small">
                Skills
                <DownOutlined />
              </Button>
            </DropdownSelect>
            <DropdownSelect<SortBy>
              mode="default"
              options={[SortBy.recentBounties, SortBy.high].map((sortBy) => ({
                label: SortNameMapping[sortBy],
                value: sortBy,
              }))}
              value={sortBy}
              onChange={setSortBy}
            >
              <Button size="small">
                Bounty
                <DownOutlined />
              </Button>
            </DropdownSelect>
          </Space>
        </Col>
      </Row>

      <Divider style={{ marginBottom: 0, marginTop: 4 }} />

      <Skeleton loading={!mounted}>
        {!data.tasks?.length ? (
          data.loading ? (
            <div
              style={{
                padding: 8,
                display: "grid",
                placeItems: "center",
                marginBottom: 650,
              }}
            >
              <Spin />
            </div>
          ) : (
            <Empty
              imageStyle={{ display: "none" }}
              className="text-secondary"
              style={{ textAlign: "left", margin: 0, marginTop: 16 }}
              description={
                !!skillIds.length
                  ? `This ${
                      workspaceId ? "project" : "organization"
                    } doesn't have any open tasks matching the selected skills at the moment`
                  : `This ${
                      workspaceId ? "project" : "organization"
                    } doesn't have any open tasks at the moment`
              }
            />
          )
        ) : (
          <TaskViewFieldsProvider fields={fields}>
            <TaskTable data={data} showHeaders={false} />
          </TaskViewFieldsProvider>
        )}
      </Skeleton>
    </div>
  );
};
