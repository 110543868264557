import { useLazyQuery } from "@apollo/client";
import {
  useCreateRule,
  useOrganizationRoles,
} from "@dewo/app/containers/rbac/hooks";
import { toTaskFormValues } from "@dewo/app/containers/task/form/util";
import { useCreateTaskFromFormValues } from "@dewo/app/containers/task/hooks";
import { CreateWorkspaceModal } from "@dewo/app/containers/workspace/create/CreateWorkspaceModal";
import {
  GetPaginatedTasksQuery,
  GetPaginatedTasksQueryVariables,
  OrganizationDetails,
  Task,
  TaskDetails,
  TaskViewSortByDirection,
  TaskViewSortByField,
  Workspace,
  WorkspaceDetails,
} from "@dewo/app/graphql/types";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import moment, { Moment } from "moment";
import React, { useCallback, useMemo } from "react";
import * as Queries from "@dewo/app/graphql/queries";
import { Modal } from "antd";
import styles from "./DuplicateWorkspace.module.less";
import { LoadingOutlined } from "@ant-design/icons";
import { stopPropagation } from "@dewo/app/util/eatClick";

const useFetchPaginatedTasks = ({ workspace }: { workspace: Workspace }) => {
  const [fetchPaginatedTasks] = useLazyQuery<
    GetPaginatedTasksQuery,
    GetPaginatedTasksQueryVariables
  >(Queries.paginatedTasks, { ssr: false });

  return useCallback(async () => {
    const tasks: Task[] = [];
    let cursor: string | undefined;
    do {
      const res = await fetchPaginatedTasks({
        variables: {
          cursor,
          filter: {
            sortBy: {
              field: TaskViewSortByField.createdAt,
              direction: TaskViewSortByDirection.ASC,
            },
            workspaceIds: [workspace.id],
          },
        },
      });
      tasks.push(...(res.data?.paginated.tasks || []));

      cursor = res.data?.paginated.cursor || undefined;
    } while (!!cursor);
    return tasks;
  }, [fetchPaginatedTasks, workspace.id]);
};

export function DuplicateWorkspace({
  organization,
  workspace,
  visible,
  onClose,
}: {
  workspace: Workspace;
  organization: OrganizationDetails | undefined;
  visible: boolean;
  onClose: () => void;
}) {
  const { logEvent } = useAmplitude();
  const roles = useOrganizationRoles(workspace.organizationId);
  const fetchPaginatedTasks = useFetchPaginatedTasks({ workspace });
  const createRule = useCreateRule();
  const createTask = useCreateTaskFromFormValues();
  const handleWorkspaceCreated = useCallback(
    async (createdWorkspace: WorkspaceDetails) => {
      const started = Date.now();
      logEvent("Duplicate workspace tasks: Started", {
        workspaceId: workspace.id,
      });
      const modal = Modal.info({
        closable: false,
        title: "Duplicating Workspace",
        content: <LoadingOutlined />,
        className: styles.noModalButtons,
      });

      const rulesFromWorkspace = (roles ?? [])
        .map((role) => role.rules.map((r) => ({ ...r, roleId: role.id })))
        .flat()
        .filter((r) => r.workspaceId === workspace.id);

      for (const rule of rulesFromWorkspace) {
        await createRule({
          workspaceId: createdWorkspace.id,
          permission: rule.permission,
          inverted: rule.inverted,
          roleId: rule.roleId,
        });
      }

      const tasks = await fetchPaginatedTasks();

      const tasksWithoutParents = tasks.filter((t) => !t.parentTaskId);

      for (const task of tasksWithoutParents) {
        const duplicatedTask = await createTask(
          toTaskFormValues(task as TaskDetails, [], [], []),
          createdWorkspace.id
        );

        const subtasks = tasks.filter((t) => t.parentTaskId === task.id);
        for (const subtask of subtasks) {
          await createTask(
            toTaskFormValues(
              { ...subtask, parentTaskId: duplicatedTask.id } as TaskDetails,
              [],
              [],
              []
            ),
            createdWorkspace.id
          );
        }
      }

      modal.destroy();

      logEvent("Duplicate workspace tasks: Finished", {
        workspaceId: workspace.id,
        duration: Date.now() - started,
      });
    },
    [createRule, createTask, fetchPaginatedTasks, logEvent, roles, workspace.id]
  );

  const initialValues = useMemo(() => {
    const { sectionId, ...restWorkspace } = workspace;
    return {
      ...restWorkspace,
      icon: workspace.icon || undefined,
      parentId: workspace.parentId || undefined,
      dates: [moment(workspace.startAt), moment(workspace.endAt)] as [
        Moment,
        Moment
      ],
      name: `${workspace.name} (Copy)`,
      ...(sectionId && { sectionId }),
    };
  }, [workspace]);

  return (
    <div onClick={stopPropagation}>
      <CreateWorkspaceModal
        title="Duplicate Workspace"
        onWorkspaceCreated={handleWorkspaceCreated}
        initialValues={initialValues}
        onClose={onClose}
        organizationId={organization?.id!}
        visible={visible}
        mode="duplicate"
      />
    </div>
  );
}
